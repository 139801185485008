#filterRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.filter {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.filter .active-display {
  padding: 8px 16px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  transition: ease-in-out 0.2s all;
}
.filter .active-display:hover {
  background-color: rgb(210, 193, 156) !important;
  border-color: transparent;
  color: #000000;
}

.filter .active-filter {
  padding-right: 25px;
  font-size: 14px;
}

.filter .MuiSvgIcon-root {
  font-size: 16px;
}

.filter .filter-options {
  padding: 0;
  margin: 0;
  width: 200px;
  border-radius: 12px;
  list-style: none;
  position: absolute;
  z-index: 2;
  top: 50px;
  overflow: hidden;
  max-height: 0;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  transition: max-height 0.3s ease;
  text-transform: capitalize;
}

.filter .filter-options.active {
  max-height: 700px;
}
.filter .filter-options > * {
  padding: 14px 20px;
  cursor: pointer;
  transition: ease-in-out all 0.3s;
  position: relative;
}
.filter .filter-options .filter-name {
  position: relative;
  z-index: 1;
}
.filter .filter-options .MuiRadio-root {
  padding: 0;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

@media (prefers-color-scheme: dark) {
  .filter .active-display {
    background-color: #131516;
  }
  .filter .filter-options {
    background-color: #131516;
  }
  .filter .active-display {
    border: 1px solid #2d2f2f;
  }

  .filter .active-display:hover,
  .filter .filter-options > *:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .filter .MuiRadio-root svg {
    fill: rgb(210, 193, 156);
  }
}

@media (prefers-color-scheme: light) {
  .filter .active-display,
  .filter .filter-options {
    background-color: #ffffff;
  }

  .filter .active-display {
    border: 1px solid #eaeaea;
  }
  .filter .active-display:hover,
  .filter .filter-options > *:hover {
    background-color: #faf8f4;
  }
  .filter .MuiRadio-root svg {
    fill: #999999;
  }
}

/*
* Full width filter
*/
.fw-filter {
  width: 100%;
  list-style-type: none;
  padding: 20px 12px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 84px;
  z-index: 1;
}
.fw-filter li {
  text-transform: capitalize;
  padding: 0 25px;
  font-size: 16px;
  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .fw-filter {
    border-bottom: 1px solid #2d2f2f;
    background-color: rgb(22, 24, 25);
  }
  .fw-filter a {
    color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  .fw-filter {
    border-bottom: 1px solid #efefef;
    background-color: #ffffff;
  }
  .fw-filter a {
    color: #000000;
  }
}

@media screen and (max-width: 768px) {
  .fw-filter {
    top: 75px;
    display: none;
  }
}
