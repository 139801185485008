.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  text-align: center;
  position: relative;
  margin-left: 16px;
}

.avatar .default {
  margin: 0 auto;
  width: 30px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
}

.account {
  display: none;
  position: absolute;
  top: 80px;
  right: 30px;
  width: 250px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.account.opened {
  display: block;
}

.account .top {
  padding: 15px 15px 0;
  text-align: center;
}
.account .top .avatar {
  margin: 0 auto 20px;
  width: 60px;
  height: 60px;
  cursor: initial;
}
.account .name {
  font-weight: 600;
}
.account .email {
  margin-top: 5px;
  opacity: 0.5;
}
.account .options {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
}
.account .options li {
  padding: 0 15px;
  cursor: pointer;
}
.account .options li > * {
  display: block;
  padding: 15px 0;
}
.account .options li:hover > *,
.account .options li:hover + li > * {
  border-color: transparent;
}
.options li:last-child {
  color: #b23b3b;
}

@media (prefers-color-scheme: dark) {
  .avatar {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .account {
    background-color: rgb(22, 24, 25);
    border: 1px solid #2d2f2f;
  }
  .account .options li > * {
    border-top: 1px solid #2d2f2f;
  }
  .account .options li:not(:last-child) > * {
    color: #fff;
  }
  .account .options li:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
@media (prefers-color-scheme: light) {
  .avatar {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .account {
    background-color: #ffffff;
    border: 1px solid #eaeaea;
  }
  .account .name {
    color: #000000;
  }
  .account .email {
    color: rgba(0, 0, 0, 0.8);
  }
  .account .options li > * {
    border-top: 1px solid #eaeaea;
  }
  .account .options li:not(:last-child) > * {
    color: #000000;
  }
  .account .options li:hover {
    background-color: rgb(210, 193, 156, 0.4);
  }
}
