.youtube-posts {
  width: auto;
  max-width: 100%;
}
.youtube-posts > * {
  transition: ease-in-out transform 0.2s !important;
}

.youtube-post {
  position: relative;
  border: 1px solid #eaeaea;
  border-radius: 12px !important;
  /*padding: 20px;
  width: calc(100% / 2 - 20px);
  margin: 10px;*/
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.youtube-post .post-text {
  position: relative;
  z-index: 1;
  overflow-wrap: break-word;
}

.youtube-post a {
  color: #000000;
}

.youtube-post .media-image,
.youtube-post .media-image img {
  width: 100%;
}

.post .yt-embed {
  position: relative;
}
.post .yt-embed .thumb {
  border-radius: 8px;
}
.post .yt-embed iframe {
  position: relative;
  z-index: 1;
}

@media (prefers-color-scheme: dark) {
  .youtube-post {
    border: none !important;
    background-color: rgb(30, 32, 33) !important;
    color: #ffffff !important;
  }
  .youtube-post a {
    color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  .youtube-post {
    border: none !important;
    color: #000000 !important;
    background-color: #ffffff !important;
  }
  .youtube-post a {
    color: #000000;
  }
}

@media screen and (min-width: 769px) {
  .youtube-tab {
    margin-right: -12px;
  }
  .youtube-posts > *:hover {
    transform: translateY(-5px);
  }
  .youtube-posts > *:hover {
    background-color: rgba(210, 193, 156, 0.2) !important;
  }
  .card-layout .youtube-post iframe {
    width: 100%;
    height: 45vw;
  }
}

@media screen and (max-width: 768px) {
  .youtube-posts {
    margin: 0 !important;
    height: auto !important;
  }
  .youtube-post {
    margin: 15px 0px !important;
    width: 100% !important;
  }
}
