@import url("./components/card.css");
@import url("./components/layout.css");
@import url("./components/form.css");
@import url("./components/buttons.css");
@import url("./components/headings.css");
@import url("./components/dashboard.css");
@import url("./components/filter.css");

/* Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  --accent-color: rgb(210, 193, 156);
  --border-color-dark: #2d2f2f;
  --border-color-light: #eaeaea;
}

* {
  outline: none;
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
html {
  scroll-behavior: smooth;
}
body {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.2em;
  transition: ease-in-out margin 0.3s;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(22, 24, 25);
    color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  body {
    color: #000000;
  }
}

.MuiBackdrop-root img {
  max-height: 100%;
}

p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 500px) {
  body {
    font-size: 14px;
  }
}
body .light-theme {
  color: #000;
}
body .dark-theme {
  color: #ffffff;
}

[role="tooltip"] {
  z-index: 9991 !important;
}

#main-content {
  min-height: 100%;
  /* width: calc(100% - 200px);
  margin-left: 200px;*/
  -webkit-transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  -moz-transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  -o-transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  transition: ease-in-out width 0.2s, ease-in-out margin 0.2s;
  position: relative;
  padding-top: 100px;
}

#main-content.fw {
  padding: 0;
  width: 100%;
  margin: 0;
}

#main-content.expanded {
  width: calc(100% - 200px);
  margin-left: 200px;
}
#main-content.no-sidebar {
  margin: 0;
  width: 100%;
}
@media screen and (min-width: 769px) {
  #main-content {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
}
@media screen and (max-width: 768px) {
  #main-content {
    padding-top: 50px;
  }
}

.back-to-top {
  position: fixed;
  right: 40px;
  bottom: 40px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  text-align: center;
  cursor: pointer;
  z-index: 5;
  transition: ease-in-out transform 0.2s !important;
}
.back-to-top:hover {
  transform: translateY(-5px);
}
.back-to-top > * {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  font-size: 35px;
}
@media (prefers-color-scheme: dark) {
  .back-to-top {
    background-color: rgb(210, 193, 156);
    color: #000000;
  }
}
@media (prefers-color-scheme: light) {
  .back-to-top {
    background-color: #000000;
    color: #ffffff;
  }
}

.MuiBackdrop-root {
  z-index: 9992 !important;
}

a,
.link {
  font-weight: 500;
  transition: ease-in-out all 0.3s;
  text-decoration: none;
}
a:hover,
.link:hover {
  color: #a79b7c;
}
@media (prefers-color-scheme: light) {
  a,
  .link {
    color: #000000;
  }
}
@media (prefers-color-scheme: dark) {
  a,
  .link {
    color: rgb(210, 193, 156);
  }
}

.link {
  font-weight: 600;
  cursor: pointer;
}

.ta-center {
  text-align: center;
}
.ta-left {
  text-align: left;
}
.ta-right {
  text-align: right;
}
.v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.justify-center {
  justify-content: center;
}

.vh-100 {
  height: 100vh;
}
.container {
  padding: 0 20px;
  position: relative;
  z-index: 2;
}
.mw-440 {
  max-width: 440px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}

.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
.strikethrough {
  text-decoration: line-through;
}

.capitalize {
  text-transform: capitalize;
}

.success-label {
  color: #1fad9d;
  background-color: rgba(31, 173, 57, 0.1);
  border-color: #1fad9d;
  padding: 15px 20px;
  margin: 0 auto 10px;
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

.error-label {
  color: #86181d;
  background-color: #ffdce0;
  border-color: rgba(27, 31, 35, 0.15);
  padding: 15px 20px;
  margin: 0 auto 10px;
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}
.error-label-2 {
  font-size: 12px;
  color: #d64b33;
}
.error-label-2 a {
  color: #d64b33;
  font-weight: bold;
  text-decoration: underline;
}

.btn.disabled {
  opacity: 0.4;
}
.disabled > img,
.disabled > span {
  opacity: 0.35;
}
.disabled:hover {
  cursor: not-allowed;
}

.MuiSvgIcon-root.small {
  width: 15px;
  height: 15px;
}

/* Font Color */
.c-white {
  color: #ffffff;
}
.c-black {
  color: #000;
}
.c-midnight-blue {
  color: #0b263a;
}
.c-orange {
  color: #c47335;
}
.c-green {
  color: rgb(0, 189, 165);
}
.c-red {
  color: #d64b33;
}
.c-light-gray {
  color: rgb(203, 214, 226);
}
.c-gray {
  color: #d3d5d8;
}
.c-teal {
  color: #3faebf;
}
.c-yellow {
  color: #f7ac1f;
}

.h-c-white:hover {
  color: #ffffff;
}
.h-c-midnightblue:hover {
  color: #0b263a;
}

/* Background color */
.bg-white {
  background-color: #fff;
}
.bg-white-opaque {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-stone {
  background-color: #7e7f73;
}
.bg-light-orange {
  background-color: #ffc166;
}
.bg-orange {
  background-color: rgb(214, 130, 49);
}
.bg-green {
  background-color: #1fad9d;
}
.bg-peach {
  background-color: #ff9966;
}
.bg-red {
  background-color: #d64b33;
}
.bg-light-gray {
  background-color: #f8f9fb;
}
.bg-midnightblue {
  background-color: #0b263a;
}
.bg-midnightblue-2 {
  background-color: #081e2e;
}
.bg-lightseagreen,
.bg-teal {
  background-color: #3faebf;
}
.bg-magenta {
  background-color: #d431bd;
}

.h-bg-white:hover {
  background-color: #ffffff;
}
.h-bg-orange:hover {
  background-color: rgb(214, 130, 49);
}
.h-bg-dark-orange:hover {
  background-color: #c0752c;
}

/* Border Color */
.b-orange {
  border-color: rgb(214, 130, 49);
}
.b-white {
  border-color: #ffffff;
}
.b-lightseagreen,
.b-teal {
  border-color: #3faebf;
}

img {
  max-width: 100%;
  height: auto;
}
table {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
table th {
  text-align: center;
  padding: 15px;
}
table td {
  padding: 15px;
}
table td img {
  max-width: 100%;
  margin: 0 auto;
}

.dropzone {
  height: auto;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}

.hidden {
  display: none;
}

.__react_component_tooltip {
  max-width: 300px;
  text-align: left;
}

.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mr-0 {
  margin-right: 0 !important;
}

.padding-top {
  padding-top: 100px;
}
.padding-bottom {
  padding-bottom: 100px;
}

/* Columns */
.col-1 {
  width: 100%;
}
.col-2 {
  width: 50%;
}
.col-3 {
  width: calc(100% / 3);
}

@media screen and (max-width: 768px) {
  .t-col-1 {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .m-col-1 {
    width: 100%;
  }
}

.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.d-flex.justify-right {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.align-top {
  align-items: flex-start;
}
.flex-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .t-no-flex {
    display: block;
  }
}
@media screen and (max-width: 500px) {
  .m-no-flex {
    display: block;
  }
}

.two-col-setting-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting-group .MuiFilledInput-root {
  margin-top: 10px;
}

.loading-circle {
  text-align: center;
}

.alert {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
.alert.error {
  background-color: #fdedee;
  border-color: #f04d62;
  color: #f04d62;
}
