#signin h2 {
  text-align: center;
  margin-bottom: 30px;
}
#signin form {
  margin-top: 20px;
}
#signin form > * {
  width: 100%;
}
#signin form > *:not(:first-child) {
  margin-top: 20px;
}
#signin .left {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;

  width: 40%;
}
#signin .form-container {
  max-width: 400px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 30px;
}
#signin .right {
  width: 60%;
  height: 100%;
  background-image: url("../../assets/current.jpg");
  position: fixed;
  right: 0;
  top: 0;
  background-position: left center;
  background-size: cover;
}

#signin .form-field {
  position: relative;
}

#signin .form-field > * {
  width: 100%;
}

#signin .form-field .loader {
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#signin .form-field .loader > * {
  width: 25px !important;
  height: 25px !important;
}

#signin .cta-button.loading {
  background-color: #93876d;
}

@media screen and (max-width: 768px) {
  #signin .right {
    display: none;
  }
  #signin .left {
    width: 100%;
  }
}

/*
  * Separator with text
  */
.separator-text {
  overflow: hidden;
  text-align: center;
  margin: 35px 0;
}
.separator-text:before,
.separator-text:after {
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.separator-text:before {
  left: 2em;
  margin-right: -50%;
}
.separator-text:after {
  right: 2em;
  margin-left: -50%;
}
@media (prefers-color-scheme: light) {
  .separator-text:before,
  .separator-text:after {
    background-color: #000000;
  }
}
@media (prefers-color-scheme: dark) {
  .separator-text:before,
  .separator-text:after {
    background-color: #ffffff;
  }
}

div#googleLogin > * > * iframe {
  margin: 0 auto !important;
}

#signin .signup {
  margin-top: 35px;
  text-align: center;
}
