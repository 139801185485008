.cta-button {
  font-family: "Poppins", sans-serif;
  padding: 12px 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-color);
  transition: ease-in-out all 0.3s;
  color: #000000;
  text-decoration: none;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.cta-button[data-disabled="true"] {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: 0.6;
}

.cta-button[data-disabled="true"]:hover {
  transform: translateY(0);
}

.cta-button:hover {
  /*background-color: #a79b7c;*/
  color: #000000;
  transform: translateY(-3px);
}

@media screen and (max-width: 500px) {
  .cta-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}

button.active {
  background-color: #1976d2;
  color: #fff;
}

.media-tabs > * {
  opacity: 0.4;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}

.media-tabs > * > *:first-child {
  margin-right: 10px;
}

.media-tabs {
  display: flex;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.media-tabs button {
  font-weight: bold;
}

@media (prefers-color-scheme: dark) {
  .media-tabs {
    color: #ffffff;
  }
  .media-tabs {
    border-bottom: 1px solid #22303c;
  }
  .media-tabs > *.active {
    opacity: 1;
    background-color: #22303c;
  }
  .media-tabs > *:hover {
    border: 1px solid #22303c;
  }
}

@media (prefers-color-scheme: light) {
  .media-tabs {
    color: #000000;
  }
  .media-tabs {
    border-bottom: 1px solid #e5e5e5;
  }
  .media-tabs > *.active {
    opacity: 1;
    background-color: #e5e5e5;
  }
  .media-tabs > *:hover {
    border: 1px solid #e5e5e5;
  }
}

/*
* Layout selector
*/
#layoutSelector .MuiButtonGroup-root {
  border-radius: 12px;
  overflow: hidden;
  height: 46.8px;
}

@media (prefers-color-scheme: dark) {
  
  #layoutSelector .MuiButtonGroup-root {
    border: 1px solid #2d2f2f;
  }
  #layoutSelector .MuiButtonGroup-grouped:not(:last-of-type) {
    border-color: #2d2f2f;
  }
  #layoutSelector .MuiButton-root {
    background-color: #131516;
    opacity: 0.3;
  }
  #layoutSelector .MuiButton-root.active {
    opacity: 1;
    color: rgb(210, 193, 156);
  }
  #layoutSelector .MuiButton-root:not(.active):hover {
    background-color: rgba(210, 193, 156, 0.3);
  }
}
@media (prefers-color-scheme: light) {
  #layoutSelector .MuiButtonGroup-grouped:not(:last-of-type) {
    border-color: #eaeaea;
  }
  #layoutSelector .MuiButton-root {
    background-color: rgb(210, 193, 156);
    opacity: 0.3;
    color: #000000;
  }
  #layoutSelector .MuiButton-root.active {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  #layoutSelector {
    display: none;
  }
}
