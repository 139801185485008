.sidebar {
  position: fixed;
  z-index: 9991;
  left: 0;
  top: 0;
  height: 100%;
  width: 200px;
  transition: margin-left ease-in-out 0.3s;
}
.sidebar.expanded {
  margin-left: 0;
}

.sidebar .logo {
  padding: 50px 30px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar .logo h2 {
  margin: 0;
  font-family: "Merriweather", serif !important;
}
.sidebar .logo img {
  max-width: 40px;
}
.sidebar .menu {
  padding: 50px 0 0;
  margin: 0;
}
.sidebar .menu-item-container:not(:first-child) {
  margin-top: 30px;
}

.sidebar .menu-section-label {
  padding: 0 30px 15px;
  color: #aeaeae;
}

.sidebar .menu-item:before {
  height: 0;
  animation-name: menu-item-animation;
  animation-duration: 0.2s;
}
.sidebar .menu-item.active:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
}
.sidebar .menu-item > *:first-child {
  margin-right: 15px;
}

.sidebar .menu-item-container.tier-1 > .menu-item {
  display: flex;
  align-items: center;
}

.sidebar .menu-item {
  display: block;
  padding: 8px 30px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: ease-in-out all 0.3s;
  text-transform: capitalize;
  color: #ffffff;
}

.sidebar .menu-item.tier-2 {
  padding: 8px 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: ease-in-out all 0.3s;
}

.sidebar .sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-container .menu-button {
  position: fixed;
  z-index: 9999;
  top: 12px;
  left: 20px;
  transition: ease-in-out all 0.3s;
}

.sidebar-container .menu-button.opened {
  top: -2px;
  left: 160px;
}

@keyframes menu-item-animation {
  0% {
    height: 0;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 100%;
  }
}

@media (prefers-color-scheme: dark) {
  .sidebar {
    border-right: 1px solid #2d2f2f;
    color: #effcef;
    background-color: rgb(22, 24, 25);
  }
  .sidebar .menu-item:hover,
  .sidebar .menu-item.active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .sidebar .menu-item.active:before {
    background-color: rgb(210, 193, 156);
  }
  .sidebar .menu-item.active {
    color: rgb(210, 193, 156);
  }
}

@media (prefers-color-scheme: light) {
  .sidebar {
    border-right: 1px solid #eaeaea;
    color: #000000;
    background-color: #ffffff;
  }
  .sidebar .menu-item {
    color: #000000;
  }
  .sidebar .menu-item:hover,
  .sidebar .menu-item.active {
    background-color: rgb(210, 193, 156, 0.4);
  }
  .sidebar .menu-item.active:before {
    background-color: #000000;
  }
  .sidebar .menu-item.active {
    color: #000000;
  }
}

@media screen and (min-width: 769px) {
  .sidebar {
    margin-left: 0;
  }
  .sidebar-container .menu-button {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    margin-left: -200px;
  }
  .sidebar-container .menu-button {
    display: block;
  }
}
