header,
header.top {
  box-shadow: none !important;
}
header {
  padding-top: 35px;
  padding-bottom: 35px;
  transition: linear all 0.2s !important;
}
header.sticky {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
header.sticky
  .search
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]) {
  height: 30px;
}
header .search {
  width: 100%;
  max-width: 500px;
}
header .search input {
  font-family: "Poppins", sans-serif;
}

@media (prefers-color-scheme: dark) {
  header {
    background-image: none !important;
    background-color: rgb(22, 24, 25) !important;
  }

  header .search-input input {
    color: #fff;
    background-color: #131516;
    border: 1px solid #2d2f2f !important;
  }
}

@media (prefers-color-scheme: light) {
  header {
    background-color: #ffffff !important;
  }
  header .menu-btn,
  header .search-icon {
    fill: #000000;
  }
}

@media screen and (min-width: 769px) {
  header {
    padding-left: 200px;
  }
  header .menu-button {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  header .MuiToolbar-root {
    padding-left: 60px;
  }
  header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header
    .search
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="button"]) {
    height: 30px;
  }
}
@media screen and (max-width: 991px) {
  header .search {
    max-width: 60%;
    font-size: 14px;
  }
}

/*
* Recent Searches
*/
#recentSearches {
  position: absolute;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
#recentSearches .top {
  justify-content: space-between;
  padding: 20px;
}
#recentSearches ul.searches {
  list-style: none;
  padding: 0;
  margin: 0;
}
#recentSearches .recent-item {
  transition: ease-in-out all 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#recentSearches .query {
  width: 100%;
  padding: 20px;
  cursor: pointer;
}
#recentSearches .clear {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 14px;
}
#recentSearches .recent-item .MuiIconButton-root {
  margin-right: 20px;
}

@media (prefers-color-scheme: dark) {
  #recentSearches {
    background-color: #131516;
  }
  #recentSearches .recent-item:hover {
    background-color: rgb(30, 32, 33);
  }
  #recentSearches .recent-item .MuiSvgIcon-root {
    fill: rgb(210, 193, 156);
  }
  #recentSearches .recent-item .MuiIconButton-root:hover,
  #recentSearches .clear:hover {
    background-color: rgba(210, 193, 156, 0.1);
  }
}

@media (prefers-color-scheme: light) {
  #recentSearches {
    background-color: #ffffff;
    color: #000000;
  }
  #recentSearches .recent-item:hover {
    background-color: #faf8f4;
  }
  #recentSearches .recent-item .MuiSvgIcon-root {
    fill: #000000;
  }
  #recentSearches .recent-item .MuiIconButton-root:hover,
  #recentSearches .clear:hover {
    background-color: rgba(210, 193, 156, 0.1);
    color: #000000;
  }
}