.reddit-posts {
  width: auto;
  max-width: 100%;
}
.reddit-posts > * {
  transition: ease-in-out transform 0.2s !important;
}

.reddit-post {
  position: relative;
  border: 1px solid #eaeaea;
  border-radius: 12px !important;
  /*padding: 20px;
  width: calc(100% / 2 - 20px);
  margin: 10px;*/
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.reddit-post .avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
}

.reddit-post .public-metrics {
  display: flex;
}
.reddit-post .public-metrics .metric > *:first-child {
  margin-right: 5px;
}
.reddit-post .post-text {
  position: relative;
  z-index: 1;
  overflow-wrap: break-word;
}

.reddit-post .post-text a {
  color: #000000;
}
.reddit-post .media-image {
  max-height: 375px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reddit-post .media-image img {
  height: auto;
  margin: auto;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.reddit-post .youtube-video iframe {
  width: 100%;
  height: 275px;
}

@media (prefers-color-scheme: dark) {
  .reddit-post {
    border: none !important;
    background-color: rgb(30, 32, 33) !important;
    color: #ffffff !important;
  }
  .reddit-post a {
    color: #ffffff;
  }
  .subreddit {
    color: rgb(210, 193, 156);
  }
}

@media (prefers-color-scheme: light) {
  .reddit-post {
    border: none !important;
    color: #000000 !important;
    background-color: #ffffff !important;
  }
  .reddit-post a {
    color: #000000;
  }
  .reddit-post .public-metrics svg {
    fill: #888888;
  }
  .reddit-post .details .subreddit {
    color: #000000;
  }
  .subreddit {
    color: #000000;
  }
}

@media screen and (min-width: 769px) {
  .reddit-tab {
    margin-right: -12px;
  }
  .reddit-posts > *:hover {
    transform: translateY(-5px);
  }
  .reddit-posts > *:hover {
    background-color: rgba(210, 193, 156, 0.2) !important;
  }
  .card-layout.reddit-posts {
    padding-top: 2px !important;
  }
  .card-layout .reddit-post .media-image {
    max-height: none;
  }
  .card-layout .reddit-post .media-image img {
    width: 100%;
  }
  .card-layout .reddit-post .youtube-video iframe {
    width: 100%;
    height: 45vw;
  }
}

@media screen and (max-width: 768px) {
  .reddit-posts {
    margin: 0 !important;
    height: auto !important;
  }
  .reddit-post {
    margin: 15px 0px !important;
    width: 100% !important;
  }
}
