.card-layout.card-layout {
  display: block !important;
  height: auto !important;
  padding-top: 15px !important;
  padding-right: 15px !important;
  width: 100% !important;
}
.card-layout > * {
  width: 100% !important;
  margin-bottom: 40px !important;
}
.card-layout > *:last-child {
  margin-bottom: 0 !important;
}

.post {
  overflow: hidden;
}
.post .featured-image {
  display: block;
  width: 100%;
  border-radius: 8px;
}
.post iframe,
.post video {
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: #000000;
  min-height: 200px;
}
.post .post-title {
  word-break: break-word;
}
.post .author .name {
  font-size: 16px;
}
.post .author-details {
  margin-bottom: 10px;
}
.post.nsfw .featured-image {
  filter: blur(1.17rem);
}
.post .media {
  overflow: hidden;
  margin-bottom: 25px;
  border-radius: 8px;
}
.post a > .top {
  flex-wrap: nowrap;
}

@media screen and (min-width: 769px) {
  .topic.posts {
    padding-top: 175px;
    margin-top: -125px;
  }
  .MuiMasonry-root {
    width: calc(100% + 40px) !important;
  }
  .post {
    transition: ease-in-out all 0.2s;
  }
  .post:hover {
    transform: translateY(-10px) !important;
  }
  .post:hover .post-title {
    text-decoration: underline;
  }
}

@media screen and (max-width: 768px) {
  .topic.posts:not(:first-child):not(:last-child) {
    padding-top: 25px;
  }
  .posts h4 {
    text-align: left !important;
    margin-bottom: 30px !important;
  }
  .post {
    margin: 20px 0 !important;
    width: 100% !important;
  }
  .post .details {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .post.twitter .details {
    flex-direction: initial;
  }
  .post .youtube-video {
    display: none;
  }
  .post .media,
  .post .yt-embed,
  .post .media-image {
    width: 30%;
    margin: 0;
  }
  .post .text {
    width: 70%;
    padding-right: 20px;
    flex-grow: 1;
  }
  .post .post-title h5 {
    font-size: 14px !important;
  }
  .post .author-details * {
    font-size: 10px !important;
  }
  .post .author .name,
  .post .username {
    font-size: 14px;
  }
}

@media (prefers-color-scheme: dark) {
  .post:hover .post-title {
    text-decoration-color: #ffffff;
  }
  .post .name {
    color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  .post:hover .post-title {
    text-decoration-color: black;
  }
  .post .name {
    color: #000000;
  }
}
