.section-title,
.section-title.MuiTypography-root {
  margin-bottom: 50px;
  text-transform: capitalize;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  line-height: 1.2em;
  letter-spacing: -3.5px;
  font-weight: 500;
}
h1,
.h1,
.MuiTypography-root.h2 {
  font-size: 54px;
}
h2,
.h2,
.MuiTypography-root.h2 {
  /*font-family: "Poppins", sans-serif;*/
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.13px;
}
h3,
.h3,
.MuiTypography-root.h3 {
  /*font-family: "Poppins", sans-serif;*/
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.27px;
}
.MuiTypography-h5 {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.MuiTypography-root,
.MuiTableCell-root {
  /*font-family: "Poppins", sans-serif !important;*/
  font-family: "Merriweather", serif !important;
}

.MuiTypography-root.MuiTypography-h6 {
  font-size: 15px;
}

.MuiTypography-root.MuiTypography-body1 {
  font-size: 14px;
}

.MuiTableCell-root {
  text-transform: capitalize;
}
.MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}

@media (prefers-color-scheme: dark) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

@media (prefers-color-scheme: light) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000000;
  }
}

@media screen and (max-width: 768px) {
  h2,
  .h2,
  .MuiTypography-root.h2 {
    font-size: 24px;
  }

  h4,
  .h4,
  .MuiTypography-root.h4 {
    font-size: 24px !important;
  }

  .MuiTypography-h5 {
    font-size: 16px !important;
  }

  .section-title,
  .section-title.MuiTypography-root {
    text-align: center;
  }
}
