.trending-topics ul {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}
.trending-topics ul li {
  padding: 10px 0;
  text-overflow: ellipsis;
}

@media (prefers-color-scheme: dark) {
}

@media (prefers-color-scheme: light) {
}

@media screen and (min-width: 769px) {
}
