.card-container > * {
  transition: ease-in-out transform 0.2s !important;
}

.card {
  position: relative;
  border: 1px solid #eaeaea;
  border-radius: 12px !important;
  padding: 20px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.card a {
  display: block;
}

.card .public-metrics {
  display: flex;
}
.card .public-metrics .metric > *:first-child {
  margin-right: 5px;
}
.card text {
  position: relative;
  z-index: 1;
  overflow-wrap: break-word;
}
.card a {
  color: #000000;
}

@media (prefers-color-scheme: dark) {
  .card {
    border: none !important;
    background-color: rgb(30, 32, 33) !important;
    color: #ffffff !important;
  }
  .card a {
    color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  .card {
    border: none !important;
    color: #000000 !important;
    background-color: #ffffff !important;
  }
  .card a,
  .card .author h6 {
    color: #000000 !important;
  }
  .card .public-metrics svg {
    fill: #888888;
  }
}

@media screen and (min-width: 769px) {
}
